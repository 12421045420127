<template>
  <div class="commodity_index">
    <div class="img_title">
      <div style="width: 1200px; margin: 0 auto; position: relative">
        <span class="box_title">商品指数</span>
      </div>
      <img style="width: 100%; height: 100%"
           src="../../assets/img/title_1.jpg"
           alt="" />
    </div>
    <div style="width:100%;overflow: auto;">
      <div class="center">
        <!--左边开始-->
        <div class="rind-left">

          <div class="left-bg2">
            <h2>恒银期货{{pageData?pageData.commodityName:''}}</h2>
            <h3 :class=" is_red ? 'green' : 'red' ">
              {{pageData?pageData.value.toFixed(2):''}}</h3>
            <h4 :class=" is_red ? 'green' : 'red2' ">
              {{pageData?pageData.per:''}}</h4>
            <div class="clear"></div>
            <h5>时间：{{pageData?pageData.publishDate:''}}</h5>
            <ul class="btns">
              <li :class="idx == 1 ? 'li-active' : ''"
                  @click="handle_(1,moment().diff(moment().subtract(1, 'months'),'days'))">近1月</li>
              <li :class="idx == 2 ? 'li-active' : ''"
                  @click="handle_(2,moment().diff(moment().subtract(1, 'quarters'),'days'))">近3月</li>
              <li :class="idx == 3 ? 'li-active' : ''"
                  @click="handle_(3,moment().diff(moment().subtract(6, 'months'),'days'))">近6月</li>
              <li :class="idx == 4 ? 'li-active' : ''"
                  @click="handle_(4,moment().diff(moment().subtract(1, 'years'),'days'))">近1年</li>
              <li :class="idx == 5 ? 'li-active' : ''"
                  @click="handle_(5,moment().diff(moment().subtract(3, 'years'),'days'))">近3年</li>
              <li :class="idx == 6 ? 'li-active' : ''"
                  @click="handle_(6,'')">成立至今</li>
            </ul>

            <div class="clear"></div>
            <div class="charts asda"></div>
            <h6>历史数据</h6>
            <div class="rind-bor4"></div>
            <div class="l2-left">指数名称：</div>
            <div class="l2-left"><strong>恒银期货{{pageData?pageData.commodityName:''}}</strong></div>
            <div class="l2-left">当前点位：</div>
            <div class="l2-left green">
              <strong>{{pageData?pageData.value.toFixed(2):''}}</strong>
            </div>
            <div class="clear"></div>
            <div class="rind-bor4"></div>
            <div class="l2-left">今日涨跌幅：</div>
            <div :class="is_(pageData.per)?'l2-left green':'l2-left red'">
              <strong>{{is_(pageData.per)?pageData.per:pageData.per?'+'+pageData.per:''}}</strong>
            </div>
            <div class="l2-left">近5日涨跌幅：</div>
            <div :class="is_(pageData.per5Day)?'l2-left green':'l2-left red'">
              <strong>
                {{is_(pageData.per5Day)?pageData.per5Day:pageData.per5Day?'+'+pageData.per5Day:''}}</strong>
            </div>
            <div class="clear"></div>
            <div class="rind-bor4"></div>
            <div class="l2-left">近一月涨跌幅：</div>
            <div :class="is_(pageData.perMonth)?'l2-left green':'l2-left red'">
              <strong>
                {{is_(pageData.perMonth)?pageData.perMonth:pageData.perMonth?'+'+pageData.perMonth:''}}</strong>
            </div>
            <div class="l2-left">近一年涨跌幅：</div>
            <div :class="is_(pageData.perYear)?'l2-left green':'l2-left red'">
              <strong>
                {{is_(pageData.perYear)?pageData.perYear:pageData.perYear?'+'+pageData.perYear:''}}</strong>
            </div>
            <div class="clear"></div>
            <div class="rind-bor4"></div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
import $ from "jquery";
import moment from "moment";
export default {
  name:'commodity_details',
  data () {
    return {
      moment,
      idx: 3,
      chartData: [],
      pageData: {},
      is_red: false
    }
  },
  computed: {
    is_ () { //is_(item) 
      return (data) => data ? data.charAt(0) == '-' : ''
    }
  },
  created () {
    let data = JSON.parse(this.$route.query.data);
    this.pageData = data.lastIndex
    this.id = data.id
  },
  mounted () {
    this.is_red = this.pageData ? this.pageData.per.charAt(0) == '-' : ''
    this.handle_(3, 183);
  },
  methods: {
    handle_ (index, data) {
      this.idx = index;
      var indexName = "恒银期货" + this.pageData.commodityName;
      var charts = echarts.init($(".charts").get(0));
      this.$axios({
        method: "get",
        headers: {
          'Authorization': this.cookies.get('token') ? this.cookies.get('token') : '',
        },
        url: `/api/web/tx/commodity_indices`,
        params: {
          commodityId: this.id,
          days: data
        },
      }).then((res) => {
        this.chartData = res.data.data.map(function (element) {
          return [element['publishDate'], element['value']];
        });
        charts.setOption({
          title: {
            show: false
          },
          legend: {
            show: false
          },
          grid: {
            left: 45,
            top: 30,
            right: 30,
            bottom: this.idx == 6 ? 80 : 60
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              label: {
                formatter: function (params) {
                  return (
                    (params.seriesData.length ? params.seriesData[0].data[0] : params.value.toFixed(2))
                  );
                }
              }
            },
            textStyle: {
              align: 'left'
            },
            formatter: function (params) {
              var data = params[0]['data'];
              return "<b>"
                + params[0]['seriesName']
                + "</b><br>交易日期："
                + moment(data[0]).format("YYYY-MM-DD")
                + "<br>指数点位："
                + data[1].toFixed(2);
            }
          },
          xAxis: {
            type: 'time',
            splitLine: {
              show: false
            },
            axisLabel: {
              // interval: 7,
              // rotate: 35,
              formatter: function (value, index) {
                return moment(value).format("YYYYMMDD");
              }
            }
          },
          yAxis: {
            type: 'value',
            axisLine: {
              show: true,
            },
            scale: true,
            min: function (value) {
              return Math.round(value.min - (value.max - value.min) / 3);
            }
          },
          // dataZoom: [
          //   {
          //     show: true,
          //     realtime: true,
          //     start: 30,
          //     end: 70,
          //     xAxisIndex: [0, 1]
          //   },
          // ],
          dataZoom: {
            xAxisIndex: 0,
            start: 0,
            /*backgroundColor: "#314B7F",*/
            show: this.idx == 6,
            labelFormatter: function (value) {
              return moment(value).format("YYYY/MM/DD");
            }
          },
          series: [{
            type: 'line',
            name: indexName,
            smooth: false,
            showSymbol: false,
            symbol: 'diamond',
            lineStyle: {
              width: 1,
              color: '#1e85f0'
            },
            areaStyle: {
              color: '#deedfb'
            },
            itemStyle: {
              color: '#ff7928'
            },
            data: this.chartData
          }]
        });
        // showArea(3);
      });
    }
  }
}
</script>
<style scoped>
.commodity_index {
  box-sizing: border-box;
  background: #f2f2f2;
  margin-bottom: -75px;
  padding-bottom: 75px;
}
.box_title {
  position: absolute;
  top: 84px;
  left: 23px;
  font-size: 40px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
}
.img_title {
  width: 100%;
  background: #ccc;
}
body,
dd,
dl,
fieldset,
form,
h1,
h2,
h3,
h4,
h5,
h6,
input,
legend,
ol,
p,
select,
td,
textarea,
th,
ul,
li {
  margin: 0;
  padding: 0;
}

body,
html {
  background: #f2f2f2;
  font-family: "微软雅黑", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 180%;
  text-align: left;
  min-width: 1200px;
}

img {
  vertical-align: middle;
  border: none;
  height: auto;
}

div,
ul,
li,
dl,
dt,
dd,
span,
img,
p,
h2,
h3,
h4 {
  list-style: none;
}

a {
  text-decoration: underline;
}

a:link {
  color: #666;
}

a:visited {
  color: #c00;
}

a:hover,
a:active,
a:focus {
  color: #c00;
}

.clear {
  clear: both;
  font-size: 0;
  height: 0;
  line-height: 0;
}

.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  visibility: hidden;
  clear: both;
  *zoom: 1; /*IE触发layout*/
}

.center {
  width: 1200px;
  margin: 0px auto;
}

.red {
  color: #e83535;
}
.green {
  color: #22c36e;
}
.rind-banner {
  width: auto;
  height: 350px;
}
.rind-banner h2 {
  font-size: 50px;
  color: #fff;
  padding: 120px 0 20px 0;
  letter-spacing: 3px;
  font-weight: 900;
}
.rind-banner h3 {
  font-size: 42px;
  color: #fff;
  padding: 20px 0 0px 0;
  letter-spacing: 2px;
  font-weight: 500;
}
.rind-left {
  margin-top: 30px;
  /* width: 880px; */
  overflow: hidden;
}
.left-bg {
  background: #fff;
  width: 100%;
  height: auto;
  height: 452px;
  margin-bottom: 20px;
}
.left-bg h5 {
  font-size: 16px;
  font-weight: 500;
  border-left: 5px solid #ea4322;
  padding-left: 15px;
  color: #e62323;
}
.left-bg .l-pd {
  padding: 15px 0 15px 15px;
}
.left-bg .left {
  float: left;
  width: 419px;
  padding: 20px;
  border-right: 1px solid #e7e7e7;
}
.left-bg .left h2 {
  font-size: 22px;
  color: #333;
}
.left-bg .left h3 {
  font-size: 26px;
  float: left;
  margin-top: 10px;
}
.left-bg .left h4 {
  font-size: 18px;
  float: left;
  margin: 12px 0 10px 10px;
}
.left-bg .left p {
  font-size: 14px;
  float: right;
  margin-top: 12px;
}
.left-bg .left p a {
  color: #1b4cb0;
  text-decoration: none;
}
.left-bg .left p a:visited {
  color: #1b4cb0;
  text-decoration: none;
}
.left-bg .left p a:hover {
  color: #ea4322;
  text-decoration: underline;
}
.left-bg .left2 {
  float: left;
  width: 380px;
  padding: 20px;
}
.left-bg .left3 {
  float: left;
  width: 140px;
  font-size: 15px;
  padding-left: 10px;
}
.left-bg .left33 {
  float: left;
  width: 130px;
  font-size: 15px;
}
.left-bg .left333 {
  float: left;
  width: 100px;
  font-size: 15px;
}
.left-bg .left4 {
  float: left;
  width: 130px;
  font-size: 18px;
}
.left-bg .left5 {
  float: left;
  width: 100px;
  font-size: 15px;
}
.left-bg .left2 dl dt {
  font-size: 15px;
  color: #999;
  line-height: 40px;
}
.left-bg .left2 dl dd {
  color: #333;
  line-height: 46px;
  transition: all 0.3s;
  cursor: pointer;
  overflow: hidden;
}
.left-bg .dd-active {
  background: #f1f5f8;
  font-weight: 600;
}
.left-bg .left6 {
  float: left;
  width: 160px;
  font-size: 15px;
  padding-left: 10px;
}
.left-bg .left66 {
  float: left;
  width: 110px;
  font-size: 15px;
  padding-left: 20px;
}
.left-bg .left666 {
  float: left;
  width: 80px;
  font-size: 15px;
}
.left-bg .left7 {
  float: left;
  width: 110px;
  font-size: 18px;
  padding-left: 20px;
}

.left-bg2 {
  background: #fff;
  height: auto;
  margin-bottom: 20px;
  padding: 20px 20px 40px 20px;
  overflow: hidden;
}
.left-bg2 h5 {
  font-size: 14px;
  font-weight: 500;
  color: #999;
}
.left-bg2 h2 {
  font-size: 26px;
  color: #333;
  margin-top: 10px;
  margin-bottom: 5px;
}
.left-bg2 h3 {
  font-size: 26px;
  float: left;
  margin-top: 10px;
}
.left-bg2 h4 {
  font-size: 20px;
  float: left;
  margin: 16px 0 0 10px;
}
.left-bg2 ul {
  margin: 15px 0 15px 0;
}
.left-bg2 ul li {
  float: left;
  width: 80px;
  text-align: center;
  border: 1px solid #476bb4;
  margin-left: -1px;
  color: #4064ad;
  height: 30px;
  line-height: 30px;
  cursor: pointer;
}
.left-bg2 .li-active {
  background: #476bb4;
  color: #fff !important;
}
.left-bg2 h6 {
  font-size: 16px;
  font-weight: 500;
  border-left: 5px solid #3a75ec;
  padding-left: 15px;
  color: #1d5ad7;
}
.left-bg2 .l2-left {
  float: left;
  width: 210px;
  font-size: 14px;
}

.rind-right {
  margin-top: 30px;
  float: right;
  width: 300px;
  background: #fff;
  height: auto;
}
.rind-right .rind-rbg {
  background: #ececec;
}
.rind-right h3 {
  font-size: 16px;
  font-weight: 500;
  padding: 16px;
}
.rind-right .rind-rpd {
  padding: 20px;
}
.rind-right p {
  font-weight: 500;
  color: #666;
  font-size: 13px;
}
.rind-right .rind-rbg2 {
  border-radius: 25px;
  background: #fd6327;
  width: 80px;
  color: #fff;
  text-align: center;
  font-weight: 500;
  margin-bottom: 15px;
}
.rind-right .rind-rbg3 {
  border-radius: 25px;
  background: #ff8b1a;
  width: 80px;
  color: #fff;
  text-align: center;
  font-weight: 500;
  margin-bottom: 15px;
}
.rind-right .rind-rbg4 {
  border-radius: 25px;
  background: #1ac1ff;
  width: 80px;
  color: #fff;
  text-align: center;
  font-weight: 500;
  margin-bottom: 15px;
}
.rind-right .rind-rbg5 {
  border-radius: 25px;
  background: #4e92e7;
  width: 80px;
  color: #fff;
  text-align: center;
  font-weight: 500;
  margin-bottom: 15px;
}
.rind-bor {
  width: 100%;
  margin: 20px 0 20px 0;
  height: 1px;
  background: linear-gradient(
    to right,
    #e7e7e7,
    #e7e7e7 3px,
    transparent 3px,
    transparent
  );
  background-size: 5px 100%;
}
.rind-bor2 {
  width: 100%;
  background: #e7e7e7;
  height: 1px;
}
.rind-bor3 {
  width: 100%;
  height: 1px;
  background: linear-gradient(
    to right,
    #ddd,
    #e7e7e7 3px,
    transparent 3px,
    transparent
  );
  background-size: 5px 100%;
}
.rind-bor4 {
  width: 100%;
  background: #e7e7e7;
  height: 1px;
  margin: 15px 0 15px 0;
}
.rind-bor5 {
  width: 100%;
  background: #e7e7e7;
  height: 2px;
  margin: 15px 0 15px 0;
}
.asda {
  width: 100%;
  height: 600px;
  flex: 1;
}
</style>